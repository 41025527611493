@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --global-bg-color: #fff; /* Tailwind CSS bg-gray-200 */
    --global-text-color: #000; /* Tailwind CSS text-black */

    --background: var(--global-bg-color);
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: #aaa;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 1rem;

    --selected: #93c5fd;
    --active: #bbf7d0;
  }

  .dark {
    --global-bg-color: #2d3748; /* #2d3748 Tailwind CSS bg-gray-800 */
    --global-text-color: #e8e8e8; /* Tailwind CSS text-white */

    --background: var(--global-bg-color);
    --selected: #2563eb;
    --active: #1e3a8a;

    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;

    --muted: 0 0 0%;
    --muted-foreground: 24 5.4% 63.9%;

    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  .bg-selected {
    background-color: var(--selected);
  }

  .bg-active {
    background-color: var(--active);
  }

  .text-muted {
    color: var(--primary) !important;
    font-size: 0.8rem;
  }

  body {
    @apply bg-background text-foreground;
    font-family: Roboto, Helvetica, Arial, sans-serif, "Segoe UI";
  }

  .ui,
  .ui.button.primary,
  .ui.button.secondary {
    background-color: var(--global-bg-color) !important;
    color: var(--global-text-color) !important;
  }

  /* 
  .MuiTableCell-root {
    @apply bg-transparent; 
  }
  .MuiTableFooter-root {
    @apply bg-transparent border-t border-border;
  }
  .MuiTableRow-root {
    @apply bg-transparent;
  }
  .MuiTableHead-root {
    @apply bg-transparent;
  }

   */
}

.MuiTableContainer-root {
  min-height: 60vh !important;
}

@media (max-width: 768px) {
  /* For tablets and mobile devices */
  .MuiTableContainer-root {
    min-height: 50vh !important;
  }
}

@media (max-width: 480px) {
  /* For small mobile devices */
  .MuiTableContainer-root {
    min-height: 40vh !important;
  }
}

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

.text-sm {
  font-size: 1.3rem;
  line-height: 2rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
